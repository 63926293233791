jQuery(document).ready(function($) { 


	 $('.s_menu a').on('click', function() { // Au clic sur un élément
      var page = $(this).attr('href'); // Page cible
      var speed = 450; // Durée de l'animation (en ms)
      $('html, body').animate( { scrollTop: $(page).offset().top }, speed ); // Go
      return false;
    });


//	WOW EFFECT //
	  var wow = new WOW(
	    {
	      boxClass:     'wow',      // animated element css class (default is wow)
	      animateClass: 'animated', // animation css class (default is animated)
	      offset:       0,          // distance to the element when triggering the animation (default is 0)
	      mobile:       false,       // trigger animations on mobile devices (default is true)
	      live:         true,       // act on asynchronously loaded content (default is true)
	      callback:     function(box) {
	        // the callback is fired every time an animation is started
	        // the argument that is passed in is the DOM node being animated
	      },
	      scrollContainer: null // optional scroll container selector, otherwise use window
	    }
	  );
	 wow.init();
  // WOW EFFECT //

  	 $('html, body').animate({
      scrollTop: 50
    }, 1000);
  
	// Gestion du margin en fonction de la hauteur de l'écran //

	 function resize_content(){
	 	var height_window = $(window).height();
	 	var width_window = $(window).width();
		var h_header = $("#masthead").outerHeight();
		var h_content = $(".txt_fond_blanc").outerHeight();
		var zone_possible_block = parseInt(height_window-h_header);
		if(width_window > 800){
			var h_content = 450;
			if($("body").hasClass('home')) h_content = 528;

			
			 if(h_content < zone_possible_block){
			 	var dif = height_window - h_header - h_content;
			 	$(".txt_fond_blanc").css('margin-top', dif);
			 	$(".txt_fond_blanc").css('margin-bottom', h_header);
			 }else{
			 	$(".txt_fond_blanc").css('margin-top',0);
			 	$(".txt_fond_blanc").css('margin-bottom', h_header);
			 }

			 console.log(h_content);
		}else{
			$(".txt_fond_blanc").css('margin-bottom', 0);
			$(".txt_fond_blanc").css('margin-top', 0);
		}

	 }
	 resize_content();
	// OWL CAROUSEL HOME
	 var owl_home_page = $("#home_sticky");

	 $( window ).resize(function() {
  		resize_content();
	});

 	if ( $( owl_home_page).length ) {
	  owl_home_page.owlCarousel({
	  	 loop:true,
	  	 autoplay:true,
	  	 autoplayTimeout:5500,
	  	 autoplaySpeed:1500,
         navSpeed:1300,
         smartSpeed:1300,
         fluidSpeed:1300,
    	 margin:0,
   		 nav:false,
   		 items:1,
 		   // animateIn: 'fadeInDown',
      //    animateOut: 'slideOutLeft'
	  });
   }
	///// ////// //// FIN DIAPORAMA HOME ///// /////// ///// 



});